import { NextPage } from 'next';
import React from 'react';
import StockHomepage from '@components/Homepage/Stock';
import { IsHomepageContext } from '../contexts/IsHomepageContext';
import { FeatureSwitchesContext } from '../types/featureSwitches';
import { handleInitialURLParams } from '@utils/page.utils';
import { ContextWithStore } from '@utils/getInitialProps/@types';
import { IOfferItemBase } from '../interfaces/IOfferItem';
import OfferListDuck from '../redux/carList/offerlist.duck';
import FilterDuck from '../redux/filters/filter.duck';
import router from 'next/router';
import { SSRError } from '@components/SSRError';
import StockService from '../services/stock/stock.service';
import {
    filtersFromUrl,
    getGeolocationByQueryParameters,
    getGeolocationNameByQueryParameters,
    getGeoLocationPropertiesFromURL,
    stockCarFiltersExportToParam,
} from '../services/filters/utils/Filters.utils';
import { getFilterCategories, getStockFilters } from '../services/filters/filterHelpers';
import globalDuck from '../redux/global/global.duck';
import { CarJourneyType, dealerApi } from '../services';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import { updateGeneralContext } from '@utils/getInitialProps/common';
import DealerDuck from '../redux/dealer/dealer.duck';
import { validateDealerId } from '@utils/Deal.utils';
import UserDuck from '../redux/user/user.duck';
import { EUserLoginType } from 'src/interfaces/User';

interface IHomePage {
    statusCode: number;
}

const HomePage: NextPage<IHomePage> = ({ statusCode }) => {
    if (!statusCode || statusCode > 404) return <SSRError />;

    return (
        <IsHomepageContext.Provider value={true}>
            <StockHomepage />
        </IsHomepageContext.Provider>
    );
};

const DESERIALIZATION_ERROR = 'DeSerializationError';
HomePage.getInitialProps = async (ctx: FeatureSwitchesContext<ContextWithStore>) => {
    let statusCode = undefined;

    const { store, res, asPath, featureContext, query } = await updateGeneralContext(ctx);

    try {
        handleInitialURLParams(store, asPath, res, router, featureContext);

        const userAuth = UserDuck.getOwnState(store.getState()).userAuth;

        // #region ALL CARS REQUEST
        let allCars: IOfferItemBase[] = [];
        let availableCars: IOfferItemBase[] = [];
        let countOfAllCars: number = 0;
        let countOfAvailableCars: number = 0;
        let dealer;

        const responseAllCars = await StockService.getStockOffers({});

        if (responseAllCars) {
            const allFilters = responseAllCars.data?.filters;
            const parsedFilters = filtersFromUrl(getStockFilters(allFilters), query, allFilters);

            const dealerId = query.idsitegeo as string;
            const isDealerIdValid = validateDealerId(dealerId);

            if (isDealerIdValid) {
                dealer = (await dealerApi.getDealer(dealerId))?.data;
            }

            const { geoLocation, distanceRadius, geoLocationName } = getGeoLocationPropertiesFromURL(query);

            const selectedFilters = stockCarFiltersExportToParam(parsedFilters);
            const isAgentDealer = userAuth.loginType === EUserLoginType.DEALER && userAuth?.detail?.dealerCode;
            const isNeededAvailableCarsRequestWithFilters =
                Object.keys(selectedFilters).length > 0 ||
                isDealerIdValid ||
                isAgentDealer ||
                geoLocation ||
                distanceRadius;

            const responseAvailableCars = isNeededAvailableCarsRequestWithFilters
                ? await StockService.getStockOffers(
                      {
                          ...stockCarFiltersExportToParam(parsedFilters),
                          ...(dealerId && { rrdi7: dealer?.attachedNVActorCode }),
                          ...(!dealerId && isAgentDealer && { rrdi7: userAuth?.detail?.dealerCode }),
                      },
                      dealerId ? { lat: dealer?.latitude, lng: dealer.longitude } : geoLocation,
                      dealerId ? 0 : distanceRadius
                  )
                : responseAllCars;

            if (query.resetFilters) {
                store.dispatch(FilterDuck.resetFilters(getStockFilters(allFilters)));
                store.dispatch(FilterDuck.resetGeoLocationProperties());
                store.dispatch(DealerDuck.setDealer(null));
            }

            if (dealerId) {
                store.dispatch(DealerDuck.setDealer(dealer));
            }

            store.dispatch(
                FilterDuck.setFilters(
                    parsedFilters,
                    getGeolocationByQueryParameters(geoLocation, dealer),
                    distanceRadius,
                    getGeolocationNameByQueryParameters(geoLocation, dealer, geoLocationName),
                    isDealerIdValid ? dealerId : null
                )
            );
            store.dispatch(FilterDuck.setFilteredFilters(getStockFilters(allFilters)));
            store.dispatch(FilterDuck.setFilterCategories(getFilterCategories(allFilters)));
            store.dispatch(
                FilterDuck.setPriceRange({
                    min: allFilters.minBudget?.value,
                    max: allFilters.maxBudget?.value,
                    availableMin: allFilters.minBudget?.value,
                    availableMax: allFilters.maxBudget?.value,
                })
            );
            store.dispatch(globalDuck.setCarJourney(CarJourneyType.STOCK));
            store.dispatch(
                FilterDuck.changeBudgetType(featureContext[FEATURES_LIST.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY_STOCK])
            );

            allCars = responseAllCars.data?.vehicles;
            availableCars = responseAvailableCars.data?.vehicles;
            countOfAllCars = responseAllCars.data?.totalVehiclesCount;
            countOfAvailableCars = responseAvailableCars.data?.totalVehiclesCount;
            statusCode = responseAllCars.statusCode;
        } else {
            statusCode = 503;
        }

        store.dispatch(OfferListDuck.setStockFilteredOffers(availableCars, countOfAvailableCars));
        store.dispatch(OfferListDuck.setStockOffers(allCars, countOfAllCars));

        return { statusCode, namespacesRequired: ['common'] };
    } catch (error: any) {
        if (error?.name === DESERIALIZATION_ERROR) {
            store.dispatch(OfferListDuck.setFilteredOffers([]));
        }
        console.error(new Date(), error);

        return { statusCode: 503, namespacesRequired: ['common'] };
    }
};

export default HomePage;
