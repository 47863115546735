import React from 'react';
import { SortBoxStyledDS } from './DS/SortBoxStyled.DS';
import { SortBoxStyledOV } from './OV/SortBoxStyled.OV';
import { SortBoxStyledAC } from './AC/SortBoxStyled.AC';
import { SortBoxStyled } from './SortBoxStyled';
import { SC } from '../../styles/theme';
import { BRAND } from '../../constants/main';
import { BrandTypes } from '../../types/brands';
import { IFilterRule } from '../../redux/filters/filter.duck.interface';

export interface SortBoxProps extends SC {
    filters: IFilterRule[];
    countOfVehicles: number;
    isSortEnabled: boolean;
}

const SortBox = (props: SortBoxProps) => {
    if (BRAND === BrandTypes.DS) return <SortBoxStyledDS {...props} />;
    if (BRAND === BrandTypes.OV) return <SortBoxStyledOV {...props} />;
    if (BRAND === BrandTypes.AC) return <SortBoxStyledAC {...props} />;
    return <SortBoxStyled {...props} />;
};

export default SortBox;
