import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { SortBoxStyled } from '../SortBoxStyled';

export const SortBoxStyledOV = styled(SortBoxStyled)`
    .dropdown-title {
        path {
            fill: ${({ theme }) => theme.colors.black};
        }
    }
    .dropdown-menu {
        background: ${({ theme }) => theme.colors.pureWhite};
        box-shadow: 0 0 1px 1px ${(props) => props.theme.colors.grey21};
        @media (max-width: ${breakpoints.sm}px) {
            right: 51%;
            bottom: -90%;
        }
        li {
            color: ${(props) => props.theme.colors.black};
            &:hover,
            &.sort-box--selected-option {
                color: ${(props) => props.theme.colors.black};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }
    }
`;
