import React from 'react';
import { BRAND } from '../../../constants/main';
import { OffersListStyledAC } from './AC/OffersListStyled.AC';
import { OffersListStyledAP } from './AP/OffersListStyled.AP';
import { OffersListStyledDS } from './DS/OffersListStyled.DS';
import { OffersListStyledOV } from './OV/OffersListStyled.OV';
import { OffersListStyled } from './OffersListStyled';
import { IOfferItem } from '../../../interfaces/IOfferItem';
import { BrandTypes } from '../../../types/brands';

export interface OffersListProps {
    isSortEnabled: boolean;
    filteredOffers: IOfferItem[];
    filteredOutOffers: IOfferItem[];
    matchingVehiclesCount: number;
}

const OffersList = (props: OffersListProps) => {
    console.log('OFFERS LIST');
    if (BRAND === BrandTypes.OV) return <OffersListStyledOV {...props} />;
    if (BRAND === BrandTypes.AC) return <OffersListStyledAC {...props} />;
    if (BRAND === BrandTypes.AP) return <OffersListStyledAP {...props} />;
    if (BRAND === BrandTypes.DS) return <OffersListStyledDS {...props} />;
    return <OffersListStyled {...props} />;
};

export default OffersList;
