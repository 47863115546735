import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';
import SortBoxTemplate from './SortBoxTemplate';
import { rem } from 'polished';

export const SortBoxStyled = styled(SortBoxTemplate)`
    margin: ${rem('10px')} 0;
    padding: 0 ${rem('18px')};
    min-height: ${rem('30px')};
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${breakpoints.md}px) {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 0 ${rem('16px')};
        margin-bottom: ${rem('16px')};
        margin-top: -${rem('10px')};
        flex-direction: column;
        background: unset;
    }

    .offer-list--vehicles-count {
        font-size: ${rem('18px')};
        text-transform: uppercase;
        padding: ${rem('15px')} 0;
        @media (max-width: ${breakpoints.md}px) {
            font-size: ${rem('12px')};
        }
    }

    .filter-title {
        text-transform: uppercase;
        font-style: normal;
        font-weight: normal;
        width: 85%;
        font-size: ${rem('18px')};
        margin: ${rem('20px')} 0 ${rem('20px')} 0;

        @media (max-width: ${breakpoints.md}px) {
            width: 100%;
            font-size: ${rem('16px')};
            border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
            padding: ${rem('10px')};
            text-align: center;
            line-height: 1;
            margin-top: 0;
        }
    }

    .dropdown-title {
        cursor: pointer;
        text-align: center;
        font-size: ${rem('14px')};
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: ${breakpoints.md}px) {
            width: 100%;
            padding: ${rem('5px')} ${rem('10px')};
        }

        > div {
            display: flex;
        }

        svg {
            margin-left: ${rem('10px')};

            @media (min-width: ${breakpoints.sm}px) {
                margin-left: ${rem('20px')};
            }
        }

        .icon-wrapper {
            height: ${rem('16px')};
        }

        path {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }

    .dropdown-menu {
        display: none;
        list-style: none;
        position: absolute;
        background: ${({ theme }) => theme.colors.white};
        right: 0;
        top: 0;
        z-index: 1000;
        margin-top: ${rem('55px')};
        text-align: right;
        font-size: ${({ theme }) => theme.fontSizes.textBase};

        @media (max-width: ${breakpoints.md}px) {
            bottom: -${rem('65px')};
            right: ${rem('15px')};
            top: unset;
        }

        &.is-open {
            display: block;
        }

        li {
            padding: ${rem('10px')} ${rem('40px')} ${rem('10px')} 0;
            cursor: pointer;

            &:hover,
            &.sort-box--selected-option {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;
