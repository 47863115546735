import apiLayerService from '../apiLayer/apiLayer.service';
import { API_LAYER_ENDPOINTS } from '../apiLayer/apiLayer.constants';
import {
    IStockModelCheckAvailabilityResponse,
    IStockModelCreateDealResponse,
    IStockModelsEndpointResponse,
    IStockVehiclesFilters,
} from './stock.types';
import { AxiosError } from 'axios';
import { TBudgetType } from '../../interfaces/IFilters';
import { IGeoLocation } from '../../redux/filters/filter.duck.interface';
import { getGeoParams } from './utils';

class StockService {
    static getStockOffers = async (
        filters: IStockVehiclesFilters,
        geoLocation?: IGeoLocation,
        distanceRadius?: number
    ) => {
        try {
            const geoLocationParams = getGeoParams(geoLocation, distanceRadius);
            const { data } = await apiLayerService.get<IStockModelsEndpointResponse>(API_LAYER_ENDPOINTS.MODELS, {
                ...filters,
                ...geoLocationParams,
            });

            return {
                data,
                statusCode: 200,
            };
        } catch (error: any) {
            throw error as AxiosError<Error>;
        }
    };

    static getStockVehicles = async (
        modelId: string,
        bodyStyleId: string,
        page: number,
        resultsPerPage: number,
        filters: IStockVehiclesFilters,
        geoLocation?: IGeoLocation,
        distanceRadius?: number
    ) => {
        try {
            const geoLocationParams = getGeoParams(geoLocation, distanceRadius);

            const { data } = await apiLayerService.get<IStockModelsEndpointResponse>(API_LAYER_ENDPOINTS.VEHICLES, {
                models: modelId,
                bodyStyles: bodyStyleId,
                page,
                resultsPerPage,
                ...filters,
                ...geoLocationParams,
            });

            return {
                data,
                statusCode: 200,
            };
        } catch (error: any) {
            throw error as AxiosError<Error>;
        }
    };

    static getTrimSlug = async (modelId: string, bodyStyleId: string) => {
        try {
            const { data } = await apiLayerService.get<{ redirectSlug: string | null }>(API_LAYER_ENDPOINTS.TRIM_SLUG, {
                model: modelId,
                bodyStyle: bodyStyleId,
            });

            return {
                data,
                statusCode: 200,
            };
        } catch (error: any) {
            throw error as AxiosError<Error>;
        }
    };

    static createStockDeal = async (uuid: string, paymentJourney: TBudgetType, geoLocation?: IGeoLocation) => {
        try {
            const { data } = await apiLayerService.post<IStockModelCreateDealResponse>(
                `${API_LAYER_ENDPOINTS.DEAL}/${uuid}/${paymentJourney}`,
                {
                    ...(geoLocation ? { extra: geoLocation } : {}),
                }
            );

            return {
                deal: data?.data?.deal,
                success: data?.success,
            };
        } catch (error: any) {
            throw error as AxiosError<Error>;
        }
    };

    static checkVehicleAvailability = async (uuid: string) => {
        try {
            const { data } = await apiLayerService.get<IStockModelCheckAvailabilityResponse>(
                `${API_LAYER_ENDPOINTS.AVAILABILITY}/${uuid}`
            );

            return {
                available: data?.available,
            };
        } catch (error: any) {
            return {
                available: false,
            };
        }
    };
}

export default StockService;
