import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import router from 'next/router';
import Carousel from '@components/Carousel';
import Main from '@components/Layout/Main';
import FAQs from '@components/FAQs';
import useTranslations from '@hooks/useTranslations';
import useLoadingReset from '@hooks/useLoadingReset';
import useMyAccountRedirect from '@hooks/useMyAccountRedirect';
import { useGTM } from '@hooks/useGTM';
import useScrollPosition from '@hooks/useScrollToPositionOnMount';
import OfferListDuck from '../../../redux/carList/offerlist.duck';
import { ContentDuck } from '../../../redux/content/content.duck';
import OffersList from '../../OffersList/Stock';
import { Redux } from 'src/redux/redux.interface';
import { FILTER_CATEGORY, IS_B2B } from '../../../constants/main';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import LoaderOverlay from '../../LoaderOverlay';
import { NotAvailableContainer } from './stock.styled';
import { GtmData } from '../../../types/gtm';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import SidebarFilterBox from '@components/SidebarFilterBox';
import FilterBox from '@components/FilterBox';
import useOnClickOutside from "@hooks/useOnClickOutside";
import {useUserDuck} from "@hooks/useUserDuck";

const StockHomepage = () => {
    const { t } = useTranslations();
    const { isStockJourney } = useCarStockJourneyCheck();
    const { pushHomePageLoad, pushProductImpressions, pushToDataLayer } = useGTM();
    const dispatch = useDispatch();

    const banners = useSelector((state: Redux) => ContentDuck.getStockBanners(state));
    const carouselAutoPlay = useSelector((state: Redux) => ContentDuck.getDynamicBannersSetting(state));
    const filteredStockOffers = useSelector(OfferListDuck.getFilteredStockOffers);
    const filteredOutStockOffers = useSelector(OfferListDuck.getFilteredOutStockOffers);
    const matchingVehiclesCount = useSelector((state: Redux) => OfferListDuck.getStockMatchingVehiclesCount(state));

    const fullBrand = t('title');

    useLoadingReset();
    useMyAccountRedirect();
    useScrollPosition();

    const filterRef = useRef();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);

    const openFilterByCategory = (category: FILTER_CATEGORY) => {
        setSelectedCategoryName(category);
        setIsFilterOpen(true);
    };
    useOnClickOutside(filterRef, () => setIsFilterOpen(false), []);

    useEffect(() => {
        dispatch(UIDuck.closeModal());
        pushHomePageLoad(true);
        pushToDataLayer({
            eventCategory: 'SolRetail::VehicleList',
            eventAction: 'VehicleModelsView::NonInteractive',
            eventLabel: 'Vehicle models view',
        } as GtmData);
    }, []);

    useEffect(() => {
        pushProductImpressions('home', filteredStockOffers);
    }, [filteredStockOffers]);

    const homePageCanonicalLink = `/stock`;

    return (
        <>
            <Main
                title={IS_B2B ? t('seo.homePage.b2b.title') : t('seo.homePage.title')}
                description={
                    IS_B2B
                        ? t('seo.homePage.b2b.description', { siteName: fullBrand })
                        : t('seo.homePage.description', { siteName: fullBrand })
                }
                canonicalLink={homePageCanonicalLink}
                hasOpenFilter={isFilterOpen}
            >
                <div ref={filterRef}>
                    <SidebarFilterBox
                        selectedCategoryName={selectedCategoryName}
                        isOpen={isFilterOpen}
                        closeFilter={() => setIsFilterOpen(false)}
                    />
                </div>
                <Carousel banners={banners} autoPlay={carouselAutoPlay} />
                <FilterBox openFilter={(category: FILTER_CATEGORY) => openFilterByCategory(category)} />
                {isStockJourney ? (
                    <>
                        {Boolean(filteredStockOffers.length) || Boolean(filteredOutStockOffers.length) ? (
                            <OffersList
                                isSortEnabled={false}
                                filteredOffers={filteredStockOffers}
                                filteredOutOffers={filteredOutStockOffers}
                                matchingVehiclesCount={matchingVehiclesCount}
                            />
                        ) : (
                            <NotAvailableContainer>
                                <span className="not-available-title">{t('errorPage.title.stock')}</span>
                                <span className="not-available-description">{t('errorPage.description.stock')}</span>
                                <div className="not-available-button">
                                    <button onClick={() => router.push(t('errorPage.returnLink.link.stock'))}>
                                        {t('errorPage.returnLink.label.stock')}
                                    </button>
                                </div>
                            </NotAvailableContainer>
                        )}
                    </>
                ) : (
                    <LoaderOverlay loading />
                )}
                <FAQs />
            </Main>
        </>
    );
};

export default StockHomepage;
