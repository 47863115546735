import React, {FC, useCallback, useMemo, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import { OffersListProps } from './index';
import OfferListItem from '../../OfferListItem';
import FilterDuck from '../../../redux/filters/filter.duck';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import { Col, Grid, Row } from '../../Grid';
import FilterBox from '@components/SidebarFilterBox';
import SortBox from '../../SortBox';
import OfferGrid from '../../OfferGrid/OfferGridStyled';
import useTranslations from '@hooks/useTranslations';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { sortOffersDescByPriority } from '@utils/Offers.utils';
import { OffersList } from '@components/OffersList/Configurable/OffersList';
import RetailPerformance from '@utils/retailPerformance';
import {
    MEASURE_FIRST_POD_LOADED_HP_CASH,
    MEASURE_FIRST_POD_LOADED_HP_FINANCE,
} from '@utils/retailPerformance/constants';
import { useJourneyType } from '@hooks/useJourneyType';
import PriceLegalText from "@components/PriceLegalText";
import {useGetNotEscapedTranslationMarkup} from "@hooks/useGetNotEscapedTranslationMarkup";

interface StyledOffersListProps extends OffersListProps {
    className?: string;
}

export const OffersListTemplate: FC<StyledOffersListProps> = ({
    filteredOffers,
    filteredOutOffers,
    matchingVehiclesCount,
    className,
    isSortEnabled,
}) => {
    const { t } = useTranslations();
    const { getNotEscapedTranslationMarkup } = useGetNotEscapedTranslationMarkup();

    const isLoading = useSelector((state) => UIDuck.isLoading(state));
    const hasMatchingVehicles = typeof matchingVehiclesCount === 'number';
    const filters = useSelector((state) => FilterDuck.getFilters(state));
    const [firstPodLoaded, setFirstPodLoaded] = useState(false);
    const { isStockJourney } = useCarStockJourneyCheck();
    const { isFinance } = useJourneyType();

    const filteredOffersWithIndex = useMemo(
        () => filteredOffers.map((offer, index) => ({ ...offer, index })),
        [filteredOffers]
    );

    const handlePodLoaded = useCallback(() => {
        if (!firstPodLoaded) {
            setFirstPodLoaded(true);

            RetailPerformance.createMeasure(
                isFinance ? MEASURE_FIRST_POD_LOADED_HP_FINANCE : MEASURE_FIRST_POD_LOADED_HP_CASH,
                'navigationStart'
            );
        }
    }, [firstPodLoaded, isFinance]);

    return (
        <OfferGrid className={className}>
            <Grid noPaddingMobile supressMediumBpMaxWidth style={{ width: '100%' }}>
                {hasMatchingVehicles && (
                    <SortBox isSortEnabled={isSortEnabled} countOfVehicles={matchingVehiclesCount} filters={filters} />
                )}
                {!filteredOutOffers.length && !filteredOffers.length ? (
                    <Row id="skip-to-content">
                        <Col
                            xs={12}
                            style={{
                                height: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <h4 style={{ marginBottom: '30px' }}>{t('label.info.noOffersFound')}</h4>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row id="skip-to-content" style={{ alignItems: 'stretch' }} noNegativeMarginMobile>
                            <OffersList
                                list={filteredOffersWithIndex.sort(sortOffersDescByPriority)}
                                isDisabled={isLoading}
                                onPodLoaded={handlePodLoaded}
                            />
                        </Row>
                    </>
                )}
                <PriceLegalText isStockJourney>
                    <p className="legalText">
                        <span
                            dangerouslySetInnerHTML={getNotEscapedTranslationMarkup('filters.stock.legal.text')}
                        ></span>
                    </p>
                </PriceLegalText>
            </Grid>
        </OfferGrid>
    );
};

export default OffersListTemplate;
