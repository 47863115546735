import axios, { AxiosResponse } from 'axios';
import { getDefaultHeaders } from './apiLayer.utils';
import { BACKEND_URL } from '../../constants/main';

class ApiLayerService {
    private API_LAYER_URL = [
        BACKEND_URL,
        process.env.NEXT_PUBLIC_MARKET,
        process.env.NEXT_PUBLIC_LANGUAGE,
        process.env.NEXT_PUBLIC_BRAND_CODE,
    ].join('/');

    async get<ResponseType = any>(
        url: string,
        params = {},
        headers: Record<string, string> = {}
    ): Promise<AxiosResponse<ResponseType>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await axios.request({
            method: 'GET',
            url: `${this.API_LAYER_URL}/${url}`,
            headers,
            params,
        });
    }

    async post<ResponseType = any>(
        url: string,
        body = {},
        headers: Record<string, string> = {},
        params = {}
    ): Promise<AxiosResponse<ResponseType>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await axios.request({
            method: 'POST',
            url: `${this.API_LAYER_URL}/${url}`,
            headers,
            data: body,
            params,
        });
    }
}

export default new ApiLayerService();
