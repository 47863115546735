import styled from 'styled-components';
import { SortBoxStyled } from '../SortBoxStyled';
import { rem } from 'polished';

export const SortBoxStyledAC = styled(SortBoxStyled)`
    .offer-list--vehicles-count {
        font-size: ${({ theme }) => theme.fontSizes.h4};
        color:  ${({ theme }) => theme.colors.grey5};
    }
    
    .dropdown-title {
        font-size: ${rem(16)};
        
        path {
            fill: ${({ theme }) => theme.colors.blue2};
        }
    }
`;
