import styled from 'styled-components';
import { rem } from 'polished';
import React from 'react';

export const NotAvailableContainerDS = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    margin: ${rem('50px')} auto ${rem('50px')} auto;
    color: ${({ theme }) => theme.colors.black};
    padding: ${rem(20)};

    .not-available-title {
        font-size: ${rem(32)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin-bottom: ${rem('50px')};
    }

    .not-available-description {
        text-align: center;
        margin-bottom: ${rem('50px')};
        line-height: ${rem('25px')};
    }

    .not-available-button {
        button {
            background-color: ${({ theme }) => theme.colors.black1};
            border-radius: ${rem(24)};
            border: none;
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.white};
            padding: ${rem(15)} ${rem(24)};
            cursor: pointer;

            &:hover,
            &:focus-visible {
                background-color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;