import styled from 'styled-components';
import { rem } from 'polished';
import { FC } from 'react';
import { BRAND } from '../../../constants/main';
import { NotAvailableContainerAC } from '@components/Homepage/Stock/AC/NotAvailableContainerStyled.AC';
import { NotAvailableContainerDS } from '@components/Homepage/Stock/DS/NotAvailableContainerStyled.DS';
import React from 'react';

const NotAvailableContainerDefault = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    margin: ${rem('50px')} auto ${rem('50px')} auto;

    .not-available-title {
        font-size: ${rem('32px')};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin-bottom: ${rem('50px')};
    }

    .not-available-description {
        text-align: center;
        margin-bottom: ${rem('50px')};
        line-height: ${rem('25px')};
    }

    .not-available-button {
        button {
            background-color: ${({ theme }) => theme.colors.primary};
            border: none;
            color: ${({ theme }) => theme.colors.white};
            padding: ${rem('15px')};
            cursor: pointer;
            height: fit-content;
            min-width: ${rem('300px')};
            width: 100%;
            font-size: ${rem('18px')};
            font-weight: 400;

            &:hover {
                background-color: ${({ theme }) => theme.colors.primaryHover};
            }

            ::-moz-focus-inner,
            :active,
            :focus-visible {
                border: 0;
                outline: none;
                -moz-outline-style: none;
                outline-style: none;
            }

            &.stock-model-card-button-disabled {
                background: ${({ theme }) => theme.colors.grey3};
                color: ${({ theme }) => theme.colors.white};
                cursor: wait;
            }
        }
    }
`;

export const NotAvailableContainer: FC = (props): JSX.Element => {
    if (BRAND === 'AC') return <NotAvailableContainerAC {...props} />;
    if (BRAND === 'DS') return <NotAvailableContainerDS {...props} />;
    return <NotAvailableContainerDefault {...props} />;
};
