import styled from 'styled-components';
import { breakpoints } from '../../../styles/theme';
import { SortBoxStyled } from '../SortBoxStyled';
import { rem } from 'polished';

export const SortBoxStyledDS = styled(SortBoxStyled)`
    .filter-title {
        width: 100%;

        &.isHome {
            text-align: center;
        }
    }

    .offer-list--vehicles-count {
        background-color: ${({ theme }) => theme.fontSizes.h4};
        font-size: ${rem(14)};

        @media (min-width: ${breakpoints.sm}px) {
            font-size: ${rem(20)};
        }
    }

    .dropdown-menu {
        @media (min-width: ${breakpoints.sm}px) {
            margin-top: ${rem(59)};
        }
    }

    .dropdown-title {
        padding: ${rem(10)} 0;

        button {
            display: flex;
            justify-content: space-between;
            border-radius: ${rem(3)};
            border: unset;
            font-size: ${({ theme }) => theme.fontSizes.h5};
            color: ${({ theme }) => theme.colors.black};

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.black};
                }
            }

            @media (min-width: ${breakpoints.sm}px) {
                padding: ${rem(14)};
                width: ${rem(200)};
                border: 1px solid ${({ theme }) => theme.colors.grey5};
                color: ${({ theme }) => theme.colors.grey29};

                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.black};
                    }
                }
            }
        }
    }
`;
