import styled from 'styled-components';
import { rem } from 'polished';
import React from 'react';

export const NotAvailableContainerAC = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    margin: ${rem('50px')} auto ${rem('50px')} auto;
    color: ${({ theme }) => theme.colors.grey5}; 

    .not-available-title {
        font-size: ${rem('32px')};
        text-transform: uppercase;
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin-bottom: ${rem('50px')};
    }

    .not-available-description {
        text-align: center;
        margin-bottom: ${rem('50px')};
        line-height: ${rem('25px')};
    }

    .not-available-button {
        button {
            background-color: ${({ theme }) => theme.colors.blue2};
            border: none;
            border-radius: ${rem(24)};
            font-size: ${({ theme }) => theme.fontSizes.h4};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            color: ${({ theme }) => theme.colors.white};
            padding: ${rem('15px')};
            cursor: pointer;
            height: fit-content;
            min-width: ${rem('300px')};
            width: 100%;

            &:hover {
                background-color: ${({ theme }) => theme.colors.grey5};
            }
        }
    }
`;